import React from "react"
import Layout from "../components/layout"
import Brands from "../components/Brands"
import Contact from "../components/Contact"
import SubHeroTwin from "../components/SubHeroTwin"
import SEO from "../components/seo"
import MainImage from "../components/MainImage"
import OrangeCTADuo from "../components/OrangeCTADuo"
import { useDesignQuery } from "../hooks/useDesignQuery"
import YearsInBusiness from "../components/YearsInBusiness"

const WebsiteDesignPage = () => {
  const data = useDesignQuery()
  const short = data.allSanityDesign.edges[0].node
  return (
    <Layout>
      <SEO
        title="Website Design"
      />
      <SubHeroTwin
        heroImage={short.heroImage.asset.fluid}
        heroTitle={short.heroTitle}
        heroSubtitle={short.heroSubtitle}
        icon1={short.heroIcon1.asset.fluid.src}
        icon2={short.heroIcon2.asset.fluid.src}
      />
      <MainImage
        mainTitle={short.mainTitle}
        mainText={short._rawMainText}
        mainImg={short.mainImg.asset.fluid.src}
        mainBtn={short.mainBtn}
        fontSize="14px"
        slug="/website-design/#contact"
      />
      <OrangeCTADuo
        title={short.orangeCtaTitle}
        text={short._rawOrangeCtaText}
        btn={short.orangeCtaBtn}
        btn2={short.orangeCtaBtn2}
        overlayImg={short.orangeCtaOverlayImg.asset.fluid}
        slug1="/magento-support"
        slug2="/shopify-support"
       />
      <Brands />
      <YearsInBusiness />
      <Contact />
    </Layout>
  )
}

export default WebsiteDesignPage