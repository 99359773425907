import { useStaticQuery, graphql } from "gatsby"
export const useDesignQuery = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allSanityDesign {
          edges {
            node {
              pageTitle
              metadescription
              heroTitle
              heroSubtitle
              mainTitle
              _rawMainText
              mainBtn
              orangeCtaTitle
              _rawOrangeCtaText
              orangeCtaBtn
              orangeCtaBtn2
              heroImage {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              heroIcon1 {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              heroIcon2 {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              mainImg {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              orangeCtaOverlayImg {
                asset {
                  fluid {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return data
}
