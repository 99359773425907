import React from "react"
import BackgroundImage from "gatsby-background-image-es5"
import Button from "./Button"
import PortableText from "@sanity/block-content-to-react"

// ONLY DIFFERENCE: TWO BUTTONS INSTEAD OF ONE
// ONLY DIFFERENCE: TWO BUTTONS INSTEAD OF ONE
// ONLY DIFFERENCE: TWO BUTTONS INSTEAD OF ONE

class OrangeCTADuo extends React.Component {
  render() {
    const { btn, btn2, title, text, overlayImg, slug1, slug2 } = this.props

    return (
        <div className="bg-orange text-white">
            <BackgroundImage fluid={overlayImg} style={{ backgroundPosition: "left" }}>
          <div className="py-25 max-w-twelve flex text-center md:text-left flex-col md:flex-row mx-auto">
            <div className="flex flex-col p-10px mx-auto w-10/12 md:w-7/12" style={{ display: "inline-block" }}>
              <h2 className="mb-6 text-2xl leading-tight font-hairline">{title}</h2>
              <div className="w-110px border mx-auto md:mx-0 border-2 border-white mb-6"></div>
              <div className="font-hairline">
                <PortableText blocks={text} />
              </div>
            </div>
            <div className="flex flex-col p-10px w-full md:mt-0 mt-5 md:w-5/12 justify-center items-center" >
              <div className="w-64 mb-4 mx-auto">
                <Button btnText={btn} bgColor="bg-brown" slug={slug1} />
              </div>
              <div className="w-64 mx-auto">
                <Button btnText={btn2} bgColor="bg-brown" slug={slug2} />
              </div>
            </div>
          </div>
        </BackgroundImage>
      </div>
    )
  }
}

export default OrangeCTADuo
